import { ApplyPositionsPageComponent } from './components/admin/apply-positions-page/apply-positions-page.component';
import { AdminGuard } from './auth/guards/admin.guard';
import { LoginComponent } from './auth/components/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { SettingsComponent } from './components/admin/settings/settings.component';
import { GlobalTeamPageComponent } from './components/global-team-page/global-team-page.component';
import { HomeComponent } from './components/home-page/home/home.component';
import { PartnersPageComponent } from './components/partners-page/partners-page.component';
import { SolutionsPageComponent } from './components/solutions-page/solutions-page.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { GetInTouchPageComponent } from './components/admin/get-in-touch-page/get-in-touch-page.component';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "our-solutions",
    component: SolutionsPageComponent
  },
  {
    path: "global-team",
    component: GlobalTeamPageComponent
  },
  {
    path: "about",
    component: AboutPageComponent
  },
  // {
  //   path: "partnes",
  //   component: PartnersPageComponent
  // },
  {
    path: "admin-settings",
    component: SettingsComponent,
    canActivate: [AdminGuard],
    canLoad: [AdminGuard]
  },
  {
    path: "admin-apply-position",
    component: ApplyPositionsPageComponent,
    canActivate: [AdminGuard],
    canLoad: [AdminGuard]
  },
  {
    path: "admin-get-in-touch",
    component: GetInTouchPageComponent,
    canActivate: [AdminGuard],
    canLoad: [AdminGuard]
  },
  {
    path: "admin-login",
    component: LoginComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
