import { AdminService } from './../services/admin.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild('fixedName', {static:true}) fixedName: ElementRef;
  @ViewChild('settingPanel', {static:true}) settingPanel: ElementRef;

  subscriptions = [];

  slideX = 0;
  currentTab = 'about';

  sliderWidth = 0;

  viewSolution = 0;
  solutionsSlideX = 0;
  viewPosition = 0;
  positionSlideX = 0;
  viewPartner = 0;
  partnerSlideX = 0;
  viewTeam = 0;
  teamSlideX = 0;

  aboutContent = {
		heading_en: "",
		heading_pt: "",
		paragraph1_en: "",
		paragraph1_pt: "",
		paragraph2_en: "",
		paragraph2_pt: "",
		temp1_en: "",
		temp1_pt: "",
		temp2_en: "",
		temp2_pt: ""
	}
  aboutContentCopy = {
		heading_en: "",
		heading_pt: "",
		paragraph1_en: "",
		paragraph1_pt: "",
		paragraph2_en: "",
		paragraph2_pt: "",
		temp1_en: "",
		temp1_pt: "",
		temp2_en: "",
		temp2_pt: ""
	}

  solutionContent = [{
    uuid:  "",
    name_en: "",
    name_pt: "",
    head_en: "",
    head_pt: "",
    para1_en: "",
    para1_pt: "",
    para2_en: "",
    para2_pt: "",
    para3_en: "",
    para3_pt: "",
    para4_en: "",
    para4_pt: ""
  }];

  solutionContentCopy = [{
    uuid:  "",
    name_en: "",
    name_pt: "",
    head_en: "",
    head_pt: "",
    para1_en: "",
    para1_pt: "",
    para2_en: "",
    para2_pt: "",
    para3_en: "",
    para3_pt: "",
    para4_en: "",
    para4_pt: ""
  }];

  careerContent = [
    {
      uuid: '',
      name_en: '',
      name_pt: '',
      desg_en: '',
      desg_pt: '',
      year_of_exp_en: '',
      year_of_exp_pt: '',
      products_en: '',
      products_pt: '',
      core_en: '',
      core_pt: '',
      location_en: '',
      location_pt: '',
      temp1_en: '',
      temp1_pt: ''
    }
  ];
  careerContentCopy = [
    {
      uuid: '',
      name_en: '',
      name_pt: '',
      desg_en: '',
      desg_pt: '',
      year_of_exp_en: '',
      year_of_exp_pt: '',
      products_en: '',
      products_pt: '',
      core_en: '',
      core_pt: '',
      location_en: '',
      location_pt: '',
      temp1_en: '',
      temp1_pt: ''
    }
  ];

  partnerContent = [{
    uuid: "",
    name_en: "",
    name_pt: "",
    content_en: "",
    content_pt: "",
    external_link: ""
  }];
  partnerContentCopy = [{
    uuid: "",
    name_en: "",
    name_pt: "",
    content_en: "",
    content_pt: "",
    external_link: ""
  }];

  memberContent = [
    {
      uuid: '',
      name_en: '',
      name_pt: '',
      positionPriority: '',
      temp1_en: '',
      temp1_pt: '',
      temp2_en: '',
      temp2_pt: '',
    }
  ];

  memberContentCopy = [
    {
      uuid: '',
      name_en: '',
      name_pt: '',
      positionPriority: '',
      temp1_en: '',
      temp1_pt: '',
      temp2_en: '',
      temp2_pt: '',
    }
  ];

  constructor(private authService: AuthService,
    private adminService: AdminService,
    private router: Router) { }

  ngOnInit() {
    this.slideX = 0;

    const sub = this.adminService.getAboutContent().subscribe(res => {
      this.aboutContent = res.result;
      this.aboutContentCopy = JSON.parse(JSON.stringify(this.aboutContent));
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub);

    const sub1 = this.adminService.getSolutionContent().subscribe(res => {
      this.solutionContent = res.result;
      this.solutionContentCopy = JSON.parse(JSON.stringify(this.solutionContent));
    }, err => {
      console.error(err);
    });

    this.subscriptions.push(sub1);
    const sub2 = this.adminService.getCareerContent().subscribe(res => {
      this.careerContent = res.result;
      this.careerContentCopy = JSON.parse(JSON.stringify(this.careerContent));
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub2);

    const sub3 = this.adminService.getSuccessStoryContent().subscribe(res => {
      this.partnerContent = res.result;
      this.partnerContentCopy = JSON.parse(JSON.stringify(this.partnerContent));
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub3);

    const sub4 = this.adminService.getTeamMemberContent().subscribe(res => {
      this.memberContent = res.result;
      this.memberContentCopy = JSON.parse(JSON.stringify(this.memberContent));
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub4);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for changing the tab based on the user clicked
   */
  changeTab(tab, index) {
    this.currentTab = tab;
    this.slideX = index * 100 * -1;

    this.viewSolution = 0;
    this.solutionsSlideX = 0;
    this.viewPosition = 0;
    this.positionSlideX = 0;
    this.viewPartner = 0;
    this.partnerSlideX = 0;
    this.viewTeam = 0;
    this.teamSlideX = 0;

    this.fixedName.nativeElement.classList.add('hide');
    setTimeout(() => {
      this.fixedName.nativeElement.classList.remove('hide');
    }, 250);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for toggling the side menu panel
   */
  slidePanel(index) {
    this.sliderWidth = index;
    if (this.sliderWidth === 0) {
      this.settingPanel.nativeElement.classList.remove('shrink');
    } else {
      this.settingPanel.nativeElement.classList.add('shrink');
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for logging out from the system for admin users
   */
  logout() {
    this.authService.logout()
      .subscribe(success => {
        if (success) {
          this.router.navigate(['/admin-login']);
        }
      });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to apply position data page
   */
  gotoApplyPos() {
    this.router.navigate(['/admin-apply-position']);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to get in touch data page
   */
  gotoOperations() {
    this.router.navigate(['/admin-get-in-touch']);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for updating about content data
   */
  updateAboutContent() {
    const sub = this.adminService.updateAboutContent(this.aboutContent).subscribe(res => {
      this.aboutContentCopy = JSON.parse(JSON.stringify(this.aboutContent));
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for doing undo if anything goes wrong
   */
  revertAboutContent() {
    this.aboutContent = JSON.parse(JSON.stringify(this.aboutContentCopy));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating previous position
   */
  prevPosition() {
    this.viewPosition -= 1;
    if (this.viewPosition <= 0) {
      this.viewPosition = 0;
      this.positionSlideX = 0;
    } else {
      this.positionSlideX = this.viewPosition * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating next position
   */
  nextPosition() {
    const contentDataLenght = document.querySelectorAll('.slider-position > .content-data').length;
    this.viewPosition += 1;
    if (this.viewPosition >= contentDataLenght - 1) {
      this.viewPosition = contentDataLenght - 1;
      this.positionSlideX = (contentDataLenght - 1) * -100;
    } else {
      this.positionSlideX = this.viewPosition * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for adding a new position based on a new uuid received from the server
   */
  addPosition() {
    const sub = this.adminService.addNewCareerContent().subscribe(res => {
      console.log(res.uuid);
      let newCareer = {
        uuid: res.uuid,
        name_en: '',
        name_pt: '',
        desg_en: '',
        desg_pt: '',
        year_of_exp_en: '',
        year_of_exp_pt: '',
        products_en: '',
        products_pt: '',
        core_en: '',
        core_pt: '',
        location_en: '',
        location_pt: '',
        temp1_en: '',
        temp1_pt: ''
      };

      this.careerContent.push(JSON.parse(JSON.stringify(newCareer)));
      this.careerContentCopy.push(JSON.parse(JSON.stringify(newCareer)));

      // logic to move to the last page
      setTimeout(() => {
        const contentDataLenght = document.querySelectorAll('.slider-position > .content-data').length;
        this.viewPosition = contentDataLenght - 1;
        this.positionSlideX = (contentDataLenght - 1) * -100;
      }, 1000);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: career
   * Description: Method for updating a career datails based on the passed career data
   */
  updateCareerContent(career) {
    // validation if any goes here

    const sub = this.adminService.updateCareerContent(career).subscribe(res => {
      for (let index = 0; index < this.careerContentCopy.length; index++) {
        if (this.careerContentCopy[index].uuid === career.uuid) {
          this.careerContentCopy[index] = JSON.parse(JSON.stringify(career));
          break;
        }
      }
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: uuid
   * Description: Method for deleting a position based on the passed uuid
   */
  deletePosition(uuid) {
    const sub = this.adminService.deleteCareerContent(uuid).subscribe(res => {
      for (let index = 0; index < this.careerContent.length; index++) {
        if (this.careerContent[index].uuid === uuid) {
          this.careerContent.splice(index , 1);
          break;
        }
      }

      for (let index = 0; index < this.careerContentCopy.length; index++) {
        if (this.careerContentCopy[index].uuid === uuid) {
          this.careerContentCopy.splice(index , 1);
          break;
        }
      }
      setTimeout(() => {
        this.prevPosition();
      }, 0);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating previous solution
   */
  prevSolution() {
    this.viewSolution -= 1;
    if (this.viewSolution <= 0) {
      this.viewSolution = 0;
      this.solutionsSlideX = 0;
    } else {
      this.solutionsSlideX = this.viewSolution * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating next solution
   */
  nextSolution() {
    const contentDataLenght = document.querySelectorAll('.slider-solution > .content-data').length;
    this.viewSolution += 1;
    if (this.viewSolution >= contentDataLenght - 1) {
      this.viewSolution = contentDataLenght - 1;
      this.solutionsSlideX = (contentDataLenght - 1) * -100;
    } else {
      this.solutionsSlideX = this.viewSolution * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for adding a new solution based on the new uuid received from the server
   */
  addSolution() {
    const sub = this.adminService.addNewSolutionContent().subscribe(res => {
      let newSolution = {
        uuid: res.uuid,
        name_en: "",
        name_pt: "",
        head_en: "",
        head_pt: "",
        para1_en: "",
        para1_pt: "",
        para2_en: "",
        para2_pt: "",
        para3_en: "",
        para3_pt: "",
        para4_en: "",
        para4_pt: ""
      };

      this.solutionContent.push(JSON.parse(JSON.stringify(newSolution)));
      this.solutionContentCopy.push(JSON.parse(JSON.stringify(newSolution)));

      // logic to move to the last page
      setTimeout(() => {
        const contentDataLenght = document.querySelectorAll('.slider-solution > .content-data').length;
        this.viewSolution = contentDataLenght - 1;
        this.solutionsSlideX = (contentDataLenght - 1) * -100;
      }, 1000);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: solution
   * Description: Method for updating solution content based on the passed solution data details
   */
  updateSolutionContent(solution) {
    // validation if any goes here

    const sub = this.adminService.updateSolutionContent(solution).subscribe(res => {
      for (let index = 0; index < this.solutionContentCopy.length; index++) {
        if (this.solutionContentCopy[index].uuid === solution.uuid) {
          this.solutionContentCopy[index] = JSON.parse(JSON.stringify(solution));
          break;
        }
      }
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: uuid
   * Description: Method for deleting solution based on the passed uuid
   */
  deleteSolution(uuid) {
    const sub = this.adminService.deleteSolutionContent(uuid).subscribe(res => {
      for (let index = 0; index < this.solutionContent.length; index++) {
        if (this.solutionContent[index].uuid === uuid) {
          this.solutionContent.splice(index , 1);
          break;
        }
      }

      for (let index = 0; index < this.solutionContentCopy.length; index++) {
        if (this.solutionContentCopy[index].uuid === uuid) {
          this.solutionContentCopy.splice(index , 1);
          break;
        }
      }
      setTimeout(() => {
        this.prevSolution();
      }, 0);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating previous team member
   */
  prevPartner() {
    this.viewPartner -= 1;
    if (this.viewPartner <= 0) {
      this.viewPartner = 0;
      this.partnerSlideX = 0;
    } else {
      this.partnerSlideX = this.viewPartner * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating next partner
   */
  nextPartner() {
    const contentDataLenght = document.querySelectorAll('.slider-partners > .content-data').length;
    this.viewPartner += 1;
    if (this.viewPartner >= contentDataLenght - 1) {
      this.viewPartner = contentDataLenght - 1;
      this.partnerSlideX = (contentDataLenght - 1) * -100;
    } else {
      this.partnerSlideX = this.viewPartner * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for adding a new partner details based on the uuid received from the server
   */
  addPartner() {
    const sub = this.adminService.addNewSuccessStoryContent().subscribe(res => {
      let newStory = {
        uuid: res.uuid,
        name_en: "",
        name_pt: "",
        content_en: "",
        content_pt: "",
        external_link: ""
      };

      this.partnerContent.push(JSON.parse(JSON.stringify(newStory)));
      this.partnerContentCopy.push(JSON.parse(JSON.stringify(newStory)));

      // logic to move to the last page
      setTimeout(() => {
        const contentDataLenght = document.querySelectorAll('.slider-partners > .content-data').length;
        this.viewPartner = contentDataLenght - 1;
        this.partnerSlideX = (contentDataLenght - 1) * -100;
      }, 1000);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: partner
   * Description: Method for updating a partner details based on the partner data passed
   */
  updatePartner(partner) {
    // validation if any goes here

    const sub = this.adminService.updateSuccessStoryContent(partner).subscribe(res => {
      for (let index = 0; index < this.partnerContent.length; index++) {
        if (this.partnerContentCopy[index].uuid === partner.uuid) {
          this.partnerContentCopy[index] = JSON.parse(JSON.stringify(partner));
          break;
        }
      }
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: uuid
   * Description: Method for deleting a partner details based on the passed uuid
   */
  deletePartner(uuid) {
    const sub = this.adminService.deleteSuccessStoryContent(uuid).subscribe(res => {
      for (let index = 0; index < this.partnerContent.length; index++) {
        if (this.partnerContent[index].uuid === uuid) {
          this.partnerContent.splice(index , 1);
          break;
        }
      }

      for (let index = 0; index < this.partnerContentCopy.length; index++) {
        if (this.partnerContentCopy[index].uuid === uuid) {
          this.partnerContentCopy.splice(index , 1);
          break;
        }
      }
      setTimeout(() => {
        this.prevPartner();
      }, 0);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event, profile
   * Description: Method for uploading a company image
   */
  uploadCompany(event, pt) {
    const file:File = event.target.files[0];

    if (file) {
      //validation goes here
      if (!(file.type === 'image/jpeg' || file.type === 'image/jpg')) {
        console.log('File type is not matched');
        return;
      }

      const formData = new FormData();
      formData.append("company", file, pt.uuid+'.jpg');
      this.adminService.uploadCompany(formData).subscribe(res => {
        setTimeout(() => {
          const id = 'successstory'+pt.uuid;
          document.getElementById(id)['src'] = './successstory/'+pt.uuid+'.jpg?t='+(new Date().getTime());
        }, 1000);
      }, err => {
        console.error(err);
      });
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: uuid
   * Description: Method for deleting a companmy image based on the passed uuid
   */
  deleteCompanyImage(uuid) {
    const sub = this.adminService.deleteCompanyImage(uuid).subscribe(res => {
      setTimeout(() => {
        const id = 'successstory'+uuid;
        document.getElementById(id)['src'] = './../../../../assets/images/placeholder.jpg';
      }, 1000);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating previous team member
   */
  prevTeamMember() {
    this.viewTeam -= 1;
    if (this.viewTeam <= 0) {
      this.viewTeam = 0;
      this.teamSlideX = 0;
    } else {
      this.teamSlideX = this.viewTeam * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating next team member
   */
  nextTeamMember() {
    const contentDataLenght = document.querySelectorAll('.slider-team > .content-data').length;
    this.viewTeam += 1;
    if (this.viewTeam >= contentDataLenght - 1) {
      this.viewTeam = contentDataLenght - 1;
      this.teamSlideX = (contentDataLenght - 1) * -100;
    } else {
      this.teamSlideX = this.viewTeam * -100;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for adding a new team member based on the uuid passed from the server
   */
  addMember() {
    const sub = this.adminService.addNewTeamMemberContent().subscribe(res => {
      let newMember = {
        uuid: res.uuid,
        name_en: '',
        name_pt: '',
        positionPriority: '',
        temp1_en: '',
        temp1_pt: '',
        temp2_en: '',
        temp2_pt: '',
      };

      this.memberContent.push(JSON.parse(JSON.stringify(newMember)));
      this.memberContentCopy.push(JSON.parse(JSON.stringify(newMember)));

      // logic to move to the last page
      setTimeout(() => {
        const contentDataLenght = document.querySelectorAll('.slider-team > .content-data').length;
        this.viewTeam = contentDataLenght - 1;
        this.teamSlideX = (contentDataLenght - 1) * -100;
      }, 1000);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: member
   * Description: Method for updating a team member data based on the passed member data input
   */
  updateMember(member) {
    // validation if any goes here

    const sub = this.adminService.updateTeamMemberContent(member).subscribe(res => {
      for (let index = 0; index < this.memberContent.length; index++) {
        if (this.memberContentCopy[index].uuid === member.uuid) {
          this.memberContentCopy[index] = JSON.parse(JSON.stringify(member));
          break;
        }
      }
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: uuid
   * Description: Method for deleting a team member data based on the passed uuid
   */
  deleteMember(uuid) {
    const sub = this.adminService.deleteTeamMemberContent(uuid).subscribe(res => {
      for (let index = 0; index < this.memberContent.length; index++) {
        if (this.memberContent[index].uuid === uuid) {
          this.memberContent.splice(index , 1);
          break;
        }
      }

      for (let index = 0; index < this.memberContentCopy.length; index++) {
        if (this.memberContentCopy[index].uuid === uuid) {
          this.memberContentCopy.splice(index , 1);
          break;
        }
      }
      setTimeout(() => {
        this.prevTeamMember();
      }, 0);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event, profile
   * Description: Method for uplading a team member image on user input change
   */
  uploadTeamMemberProfile(event, mem) {
    const file:File = event.target.files[0];

    if (file) {
      //validation goes here
      if (!(file.type === 'image/jpeg' || file.type === 'image/jpg')) {
        console.log('File type is not matched');
        return;
      }

      const formData = new FormData();
      formData.append("profile", file, mem.uuid+'.jpg');
      this.adminService.uploadTeamMember(formData).subscribe(res => {
        setTimeout(() => {
          const id = 'team-member'+mem.uuid;
          document.getElementById(id)['src'] = './team-member/'+mem.uuid+'.jpg?t='+(new Date().getTime());
        }, 1000);
      }, err => {
        console.error(err);
      });
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: uuid
   * Description: Method for deleting a team member image based on the passed uuid
   */
  deleteTeamMemberImage(uuid) {
    const sub = this.adminService.deleteTeamMemberImage(uuid).subscribe(res => {
      setTimeout(()=> {
        const id = 'team-member'+uuid
        document.getElementById(id)['src'] = './../../../../assets/images/placeholder.jpg';
      }, 1000);
    }, err => {
      console.log(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
