import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { config } from './../config';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http: HttpClient) { }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: formData
   * Description: Method for uploding general category CV with progress bar events
  */
  upload(formData) {
    return this.http.post<any>(`${config.apiUrl}/upload`, formData,{
        reportProgress: true,
        observe: 'events'
      }).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: formData
   * Description: Method for uploading CV with category
  */
  uploadCV(formData) {
    return this.http.post<any>(`${config.apiUrl}/uploadCV`, formData).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: formData
   * Description: Method for sending data for get in touch inforamtion from user
  */
  getInTouch(formData) {
    return this.http.post<any>(`${config.apiUrl}/getintouch`, formData).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }
}
