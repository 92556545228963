import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { config } from './../../../config';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for getting all the applied candidate details list
   */
  getAllAppliedCandidates() {
    return this.http.get<any>(`${config.apiUrl}/getAllAppliedCandidates`).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for gettting all the query information list
   */
  getAllQueryInfodata() {
    return this.http.get<any>(`${config.apiUrl}/getAllQueryInfodata`).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for getting all the candidate CV list
   */
  getCandidateCV() {
    return this.http.get<any>(`${config.apiUrl}/candidateCV`).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for getting all the about page content in EN and PT language
   */
  getAboutContent() {
    return this.http.get<any>(`${config.apiUrl}/getAboutContent`).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: queryData
   * Description: Method for updating all the about page content in EN and PT language
   */
  updateAboutContent(queryData) {
    return this.http.post<any>(`${config.apiUrl}/updateAboutContent`, queryData).pipe(
      map((res: any) => {
        return <any>res;
      }),
      catchError((err) => {
        return throwError(err);
      }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: N/A
     * Description: Method for getting all the solutions page content in EN and PT language
     */
    getSolutionContent() {
      return this.http.get<any>(`${config.apiUrl}/getSolutionContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: queryData
     * Description: Method for updating all the solution page content in EN and PT language
     */
    updateSolutionContent(queryData) {
      return this.http.post<any>(`${config.apiUrl}/updateSolutionContent`, queryData).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: uuid
     * Description: Method for deleting a solution content based on the passed uuid
     */
    deleteSolutionContent(uuid) {
      return this.http.post<any>(`${config.apiUrl}/deleteSolutionContent`, {uuid: uuid}).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: N/A
     * Description: Method for getting a new solution data with a unique uuid passed from server
     */
    addNewSolutionContent() {
      return this.http.get<any>(`${config.apiUrl}/addNewSolutionContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: N/A
     * Description: Method for getting all the career page content in EN and PT language
     */
    getCareerContent() {
      return this.http.get<any>(`${config.apiUrl}/getCareerContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: queryData
     * Description: Method for updating all the career page content in EN and PT language
     */
    updateCareerContent(queryData) {
      return this.http.post<any>(`${config.apiUrl}/updateCareerContent`, queryData).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: uuid
     * Description: Method for deleting a career content based on the passed uuid
     */
    deleteCareerContent(uuid) {
      return this.http.post<any>(`${config.apiUrl}/deleteCareerContent`, {uuid: uuid}).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: N/A
     * Description: Method for getting a new career data with a unique uuid passed from server
     */
    addNewCareerContent() {
      return this.http.get<any>(`${config.apiUrl}/addNewCareerContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: N/A
     * Description: Method for getting all the success stories page content in EN and PT language
     */
    getSuccessStoryContent() {
      return this.http.get<any>(`${config.apiUrl}/getSuccessStoryContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: queryData
     * Description: Method for updating all the success story page content in EN and PT language
     */
    updateSuccessStoryContent(queryData) {
      return this.http.post<any>(`${config.apiUrl}/updateSuccessStoryContent`, queryData).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: uuid
     * Description: Method for deleting a success story content based on the passed uuid
     */
    deleteSuccessStoryContent(uuid) {
      return this.http.post<any>(`${config.apiUrl}/deleteSuccessStoryContent`, {uuid: uuid}).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: N/A
     * Description: Method for getting a new success story data with a unique uuid passed from server
     */
    addNewSuccessStoryContent() {
      return this.http.get<any>(`${config.apiUrl}/addNewSuccessStoryContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: formData
     * Description: Method for uploading a company image for the susccess story page
     */
    uploadCompany(formData) {
      return this.http.post<any>(`${config.apiUrl}/uploadCompany`, formData).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: uuid
     * Description: Method for deleting a company image for a success story based on the uuid passed
     */
    deleteCompanyImage(uuid) {
      return this.http.post<any>(`${config.apiUrl}/deleteCompanyImage`, {uuid: uuid}).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: N/A
     * Description: Method for getting all team members data list
     */
    getTeamMemberContent() {
      return this.http.get<any>(`${config.apiUrl}/getTeamMemberContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: queryData
     * Description: Method for updating a team member data with a queryData passed
     */
    updateTeamMemberContent(queryData) {
      return this.http.post<any>(`${config.apiUrl}/updateTeamMemberContent`, queryData).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: uuid
     * Description: Method for deleting a team member data with uuid passed
     */
    deleteTeamMemberContent(uuid) {
      return this.http.post<any>(`${config.apiUrl}/deleteTeamMemberContent`, {uuid: uuid}).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: queryData
     * Description: Method for adding a new team member data with a uuid passed from the server
     */
    addNewTeamMemberContent() {
      return this.http.get<any>(`${config.apiUrl}/addNewTeamMemberContent`).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: formData
     * Description: Method for uploading a team member image for home page profiles
     */
    uploadTeamMember(formData) {
      return this.http.post<any>(`${config.apiUrl}/uploadTeamMember`, formData).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }

    /**
     * Company: Findmore Digital, Dubai
     * Year: 2022
     * Owner: Surajit Das
     * Input Fields: uuid
     * Description: Method for deleting a team member image for home page profiles based on the passed uuid
     */
    deleteTeamMemberImage(uuid) {
      return this.http.post<any>(`${config.apiUrl}/deleteTeamMemberImage`, {uuid: uuid}).pipe(
        map((res: any) => {
          return <any>res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
    }
  }

