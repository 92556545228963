import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  language = 'en';
  language$: BehaviorSubject<string> = new BehaviorSubject('en');

  displayNames = {
    'en': {
      'header-page': {
        about: 'About',
        solutions: 'Solutions',
        careers: 'Careers',
        partners: 'Partners',
        getInTouch: 'Get In Touch',
        theFutureIs: 'The Future Is',
        digital: 'Digital',
        weHelp: 'We help companies in their Digital Transformation journey so that they drive their business in an Agile and fast way.'
      },
      'partners-home': {
        outPartner: 'OUR PARTNERS'
      },
      'about-home': {
        about: 'About',
        weAreExperts: 'We are experts on organizational digital transformation and we help changing the landscape of IT companies.',
        para1: 'We are experts on organizational digital transformation and we help changing the landscape of IT companies. With our multidisciplinary team, creative, dynamic and committed to delivering value to our customers, we offer world class projects moving them to modern IT solutions, which offer the speed and agility necessary to tackle the challenges of today’s markets.',
        para2: 'Our partnership with Software AG has awarded us world Market Excellence Partner of the year and shows we are a global reach trustful SI with startup mentality!',
        solutions: 'Solutions',
        softwear: 'Software',
        nearshore: 'Nearshore',
        news: 'News',
        awards: 'Findmore Digital Wins Award Innovation Partner of The Year 2022',
        read: 'READ',
        teacnicalSolutions: 'Technical Solutions'
      },
      'workstyle-home': {
        workstyle: 'workstyle',
        header: 'Over 15 years experience, with own proven methodology, on all Digital Transformation journeys and challenges!',
        para1: 'Our dedicated agile EAI, API, IoT, BPM.. software development teams are present at every stage of your journey to success, from ideation right through to delivery. Our global delivery model offers you onsite, near-shore, off-shore or hybrid capabilities. What’s more, our flexible team can provide it with round-the-clock support, regardless of what time zone you’re in.',
        para2: 'We’ve done it for a wide range of industry verticals around the world and we’d be delighted to do it for you too!!',
        getInTouch: 'Get in touch'
      },
      'business-solution-home': {
        businessSolution: 'Business Solutions',
        outsourcing: 'outsourcing',
        nearshore: 'nearshore',
        softwareProjects: 'software projects',
        outsourcingPara1: 'Our secret is a multidisciplinary team created by experts who have vast technological knowledge that goes from Opensource, Microsoft, Java, IBM, Mendix to Oracle.',
        outsourcingPara2: 'Let’s talk about business?',
        // nearshorePara1: 'Our size and focus assures our customers have full access to our management team.',
        // nearshorePara2: 'The transparent partnership in which our entire management team works with you and the customized flexible engagement models provided are the key to ensure long-term mutual success.',
        nearshorePara1: 'The Nearshore strategy is nothing more than hiring a remote team that is geographically close to your company, which guarantees several corporate benefits, including cost reduction, logistics, and cultural proximity.',
        nearshorePara2: 'The nearshore strategy is one of the easiest ways to expand your team remotely, achieve scalability, and stay competitive. Using nearshore can be the game-changer for your business.',
        softwareProjectsPara1: 'We create products and clear and intuitive solutions for the needs of your organization.',
        softwareProjectsPara2: 'With experienced professionals and with agile methodologies, we develop high technology for some of the biggest companies, at a global level.',
      },
      'companies-home': {
        weContribute: 'We contribute to the success of these companies:'
      },
      'footer-page': {
        offices: 'offices',
        lisbon: 'LISBON',
        lisbonAddress1: 'Av. D Joao II, Lt42, Escritorio 206, 1990-095 Lisboa, Portugal',
        lisbonAddress2: 'Tel: +351 218 208 394',
        dubai: 'DUBAI',
        dubaiAddress: 'UAE, Dubai, Business Bay, Exchange Tower, 17th Floor, Office #1703',
        santiago: 'SANTIAGO - CHILE',
        santiagoAddress1: 'Marchant Pereira 150, oficina 901',
        santiagoAddress2: 'Providencia Santiago Chile',
        request: 'REQUEST A CONTACT',
        email: 'EMAIL*',
        message: 'MESSAGE*',
        agree: 'I agree with the',
        terms: 'Terms & Conditions',
        send: 'send',
        generalInquery: 'GENERAL INQUIRIES',
        copyright: '©2022 FINDMORE DIGITAL'
      },
      'about-page': {
        about: 'About',
        solutions: 'Solutions',
        careers: 'Careers',
        partners: 'Partners',
        getInTouch: 'Get In Touch',
        name: 'About',
        gallery: 'Gallery'
      },
      'solutions-page': {
        about: 'About',
        solutions: 'Solutions',
        careers: 'Careers',
        partners: 'Partners',
        getInTouch: 'Get In Touch',
        weDeliverCustom: 'We Deliver Custom',
        subHead1: 'Our secret is a multidisciplinary team created by specialists who have vast technological knowledge ranging from Opensource, Microsoft, Java, IBM, Liferay to Oracle.',
        subHead2: 'Shall we talk business?',
        name: 'Our Solutions'
      },
      'global-team-page': {
        about: 'About',
        solutions: 'Solutions',
        careers: 'Careers',
        partners: 'Partners',
        getInTouch: 'Get In Touch',
        joinOur: 'JOIN OUR',
        subHead: "Take a look at our job openings or send us your spontaneous application. We can't wait to meet you.",
        name: 'OPEN POSITIONS',
        apply: 'Apply',
        cantFind: 'Can’t find anything that matches your profile?',
        uploadResume: 'Upload Your Resume Here',
        noFile: 'No file has been attached yet',
        uploadCV: 'Upload CV',
        uploadingFormat: 'Uploading format',
        maxSize: 'Maximum Size Limit',
        cancelUpload: 'Cancel Upload',
        onlyPDF: 'Only PDF format files are allowed.',
        maxPDFSize: 'Maximum PDF size should not exceed of 2 MB.',
        uploadSuccessful: 'Your CV has been uploaded successfully.',
        uploadError: 'Error occured while uploading your CV. Please try again!'
      },
      'partners-page': {
        about: 'About',
        solutions: 'Solutions',
        careers: 'Careers',
        partners: 'Partners',
        getInTouch: 'Get In Touch',
        successStory: 'SUCCESS STORIES',
        withOur: 'WITH OUR',
        partner: 'PARTNERS',
        name: 'PARTNERS',
        more: 'more...'
      }
    },
    'pt': {
      'header-page': {
        about: 'Sobre',
        solutions: 'Soluções',
        careers: 'Carreiras',
        partners: 'Sócias',
        getInTouch: 'Entrar em contato',
        theFutureIs: 'O futuro é',
        digital: 'Digital',
        weHelp: 'Ajudamos as empresas na sua jornada de Transformação Digital para que inovem os seus negócios de forma ágil e rápida.'
      },
      'partners-home': {
        outPartner: 'NOSSOS PARCEIROS'
      },
      'about-home': {
        about: 'Sobre',
        weAreExperts: 'Somos especialistas em transformação digital organizacional e ajudamos a mudar o panorama das empresas de TI.',
        para1: 'Com a nossa equipa multidisciplinar, criativa, dinâmica e empenhada em fornecer valor aos nossos clientes, oferecemos projetos de classe mundial, levando-os a soluções informáticas modernas, que oferecem a rapidez e agilidade necessárias para enfrentar os desafios dos mercados atuais.',
        para2: 'A nossa parceria com a Software AG premiou-nos como Parceiro de Excelência de Mercado Mundial do ano e mostra que somos um SI de alcance global com mentalidade de startup!',
        solutions: 'Soluções',
        softwear: 'Software',
        nearshore: 'Nearshore',
        news: 'Notícias',
        awards: 'Findmore Digital Wins Award Innovation Partner of The Year 2022',
        read: 'LER',
        teacnicalSolutions: 'Soluções Técnicas'
      },
      'workstyle-home': {
        workstyle: 'estilo de trabalho',
        header: 'Mais de 15 anos de experiência, e metodologia própria comprovada, em todas as viagens e desafios da Transformação Digital!',
        para1: 'As nossas equipas de desenvolvimento de software EAI, API, IoT, BPM... estão presentes em todas as fases da sua jornada para o sucesso, desde a idealização até à entrega. O nosso modelo de entrega global oferece-lhe capacidades no local, near-shore, off-shore ou híbridas. Além disso, a nossa equipa flexível pode fornecer-lhe apoio 24 horas por dia, independentemente do fuso horário em que se encontre.',
        para2: 'Fizemo-lo para uma vasta gama de verticais da indústria em todo o mundo e teríamos todo o prazer em fazê-lo também para si!',
        getInTouch: 'Entrar em contato'
      },
      'business-solution-home': {
        businessSolution: 'Solução de negócio',
        outsourcing: 'outsourcing',
        nearshore: 'nearshore',
        softwareProjects: 'software projects',
        outsourcingPara1: 'O nosso segredo é uma equipa multidisciplinar criada por especialistas que possuem vastos conhecimentos tecnológicos que vão desde Opensource, Microsoft, Java, IBM, Liferay até Oracle.',
        outsourcingPara2: 'Vamos falar de negócios?',
        // nearshorePara1: 'A nossa dimensão e foco asseguram aos nossos clientes o pleno acesso à nossa equipa de gestão.',
        // nearshorePara2: 'A parceria transparente em que toda a nossa equipa de gestão trabalha consigo e os modelos de compromisso flexíveis e personalizados fornecidos são a chave para assegurar o sucesso mútuo a longo prazo.',
        nearshorePara1: 'A estratégia Nearshore nada mais é do que contratar uma equipa remota geograficamente próxima da sua empresa, o que garante vários benefícios corporativos, incluindo redução de custos, logística, e proximidade cultural.',
        nearshorePara2: 'A estratégia nearshore é uma das formas mais fáceis de expandir a sua equipa remotamente, alcançar escalabilidade, e manter-se competitiva. Utilizar o nearshore pode ser a mudança de jogo para o seu negócio.',
        softwareProjectsPara1: 'Criamos produtos e soluções claras e intuitivas para as necessidades da sua organização.',
        softwareProjectsPara2: 'Com profissionais experientes e com metodologias ágeis, desenvolvemos alta tecnologia para algumas das maiores empresas, a um nível global.',
      },
      'companies-home': {
        weContribute: 'Contribuímos para o sucesso destas empresas:'
      },
      'footer-page': {
        offices: 'escritórios',
        lisbon: 'LISBOA',
        lisbonAddress1: 'Av. D João II, Lt42, Escritório 206, 1990-095 Lisboa, Portugal',
        lisbonAddress2: 'Tel: +351 218 208 394',
        dubai: 'Dubai',
        dubaiAddress: 'Emirados Árabes Unidos, Dubai, Business Bay, Exchange Tower, 17º andar, escritório nº 1703',
        santiago: 'SANTIAGO - CHILE',
        santiagoAddress1: 'Marchante Pereira 150, oficina 901',
        santiagoAddress2: 'Providencia Santiago Chile',
        request: 'SOLICITE UM CONTATO',
        email: 'O EMAIL*',
        message: 'MENSAGEM*',
        agree: 'Eu concordo com o',
        terms: 'termos e Condições',
        send: 'mandar',
        generalInquery: 'QUESTÕES GERAIS',
        copyright: ''
      },
      'about-page': {
        about: 'Sobre',
        solutions: 'Soluções',
        careers: 'Carreiras',
        partners: 'Sócias',
        getInTouch: 'Entrar em contato',
        name: 'Sobre',
        gallery: 'Galeria'
      },
      'solutions-page': {
        about: 'Sobre',
        solutions: 'Soluções',
        careers: 'Carreiras',
        partners: 'Sócias',
        getInTouch: 'Entrar em contato',
        weDeliverCustom: 'Nós entregamos',
        subHead1: 'O nosso segredo é uma equipa multidisciplinar criada por especialistas que possuem vastos conhecimentos tecnológicos que vão desde Opensource, Microsoft, Java, IBM, Liferay até Oracle.',
        subHead2: 'Vamos falar de negócios?',
        name: 'Nossas Soluções'
      },
      'global-team-page': {
        about: 'Sobre',
        solutions: 'Soluções',
        careers: 'Carreiras',
        partners: 'Sócias',
        getInTouch: 'Entrar em contato',
        joinOur: 'JUNTE-SE AO NOSSO',
        subHead: "Consulte as nossas vagas de emprego ou envie-nos a sua candidatura espontânea. Mal podemos esperar para nos encontrarmos consigo.",
        name: 'POSIÇÕES ABERTAS',
        apply: 'Aplicar',
        cantFind: 'Não consegue encontrar nada que corresponda ao seu perfil?',
        uploadResume: 'Envie o seu currículo',
        noFile: 'Nenhum arquivo foi anexado ainda',
        uploadCV: 'Carregar CV',
        uploadingFormat: 'Formato de upload',
        maxSize: 'Limite Máximo de Tamanho',
        cancelUpload: 'Cancelar upload',
        onlyPDF: 'Somente arquivos em formato PDF são permitidos.',
        maxPDFSize: 'O tamanho máximo do PDF não deve exceder 2 MB.',
        uploadSuccessful: 'Seu currículo foi carregado com sucesso.',
        uploadError: 'Ocorreu um erro ao enviar seu currículo. Por favor, tente novamente!'
      },
      'partners-page': {
        about: 'Sobre',
        solutions: 'Soluções',
        careers: 'Carreiras',
        partners: 'Sócias',
        getInTouch: 'Entrar em contato',
        successStory: 'HISTÓRIAS DE SUCESSO',
        withOur: 'COM NOSSO',
        partner: 'PARCEIROS',
        name: 'PARCEIROS',
        more: 'mais...'
      }
    }
  }

  constructor() { }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for getting the current language which user has set up
  */
  getLanguaage() {
    return this.language;
  }
}
