import { HeaderService } from './../../../services/header.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners-home',
  templateUrl: './partners-home.component.html',
  styleUrls: ['./partners-home.component.scss']
})
export class PartnersHomeComponent implements OnInit, OnDestroy {
  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'partners-home';
  slideX = 0;
  constructor(private headerService: HeaderService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to previous partners
   */
  prev() {
    if (window.innerWidth < 480) {
      this.slideX += 100;
    } else {
      this.slideX += 250;
    }
    if (this.slideX > 0) {
      this.slideX = 0;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to next partners
   */
  next() {
    const adjustment = 0;
    if (window.innerWidth < 480) {
      this.slideX -= 100;
    } else {
      this.slideX -= 250;
    }
    const links = document.querySelector('.partners-home-container .company-links .links');
    const scrollWidth = links['scrollWidth'];
    const totalWidth = links['offsetWidth'];
    if (this.slideX < (scrollWidth - totalWidth) * -1) {
      this.slideX = (scrollWidth - totalWidth) * -1;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
