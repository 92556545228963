import { UtilityService } from './../../../services/utility.service';
import { MainService } from './../../../services/main.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GetInTouchComponent implements OnInit, OnDestroy {

  subscriptions = [];
  userData = {
    name: '',
    email: '',
    subject: '',
    message: '',
    isAgreed: false
  };

  validationErrMsg = {
    name: false,
    email: false,
    subject: false,
    message: false
  }

  constructor(public dialogRef: MatDialogRef<GetInTouchComponent>,
    @Inject(MAT_DIALOG_DATA) public service: any,
    private mainService: MainService,
    private utilityService: UtilityService) { }

  ngOnInit() {
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for closing apply position dialog component
   */
  closeDialog() {
    this.dialogRef.close({status: ''});
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening the teams component as pop up dialog box
   */
  openTeamsConditions() {
    this.dialogRef.close({status: true});
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: message
   * Description: Method for opening information box pop up with message passed
   */
  openInformationBox(message) {
    this.dialogRef.close({status: false, message: message});
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening get in touch component as pop up dialog box
   */
  getInTouch() {
    const validations = [];
    if (this.userData.name.trim() === '') {
      this.validationErrMsg.name = true;
      validations.push('name');
    }

    if (this.userData.email.trim() === ''
      || !this.utilityService.isEmailValid(this.userData.email)) {
      this.validationErrMsg.email = true;
      validations.push('email');
    }

    if (this.userData.subject.trim() === '') {
      this.validationErrMsg.subject = true;
      validations.push('subject');
    }

    if (this.userData.message.trim() === '') {
      this.validationErrMsg.message = true;
      validations.push('message');
    }

    if (validations.length > 0) {
      return;
    }

    this.mainService.getInTouch(this.userData).subscribe(res => {
      this.openInformationBox(res.message);
    }, err => {
      console.error(err);
    })
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
