import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  styleUrls: ['./information-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InformationBoxComponent implements OnInit, OnDestroy {

  subscriptions = [];
  constructor(public dialogRef: MatDialogRef<InformationBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public information: any) { }

  ngOnInit() {
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for closing apply position dialog component
   */
  closeDialog(data) {
    this.dialogRef.close(data);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
