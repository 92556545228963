import { HeaderService } from './../../../services/header.service';
import { AdminService } from './../../admin/services/admin.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-technical-solutions-scroller',
  templateUrl: './technical-solutions-scroller.component.html',
  styleUrls: ['./technical-solutions-scroller.component.scss']
})
export class TechnicalSolutionsScrollerComponent implements OnInit, OnDestroy {

  subscriptions = [];
  language = '';

  solutionContent = [{
    uuid:  "",
    name_en: "",
    name_pt: "",
    head_en: "",
    head_pt: "",
    para1_en: "",
    para1_pt: "",
    para2_en: "",
    para2_pt: "",
    para3_en: "",
    para3_pt: "",
    para4_en: "",
    para4_pt: ""
  }];

  slideX = 0;
  adjustment = 96;

  constructor(private adminService: AdminService,
    private headerService: HeaderService,
    private router: Router) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });

    const sub1 = this.adminService.getSolutionContent().subscribe(res => {
      this.solutionContent = res.result;
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub1);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to previous technical solutions
   */
  prev() {
    this.slideX += 250;
    if (this.slideX > 0) {
      this.slideX = 0;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to next technical solutions
   */
  next() {
    this.slideX -= 250;
    const totalWidth = document.querySelector('.technical-solutions-scroller-container .solutions')['scrollWidth'];
    if (this.slideX - window.innerWidth + this.adjustment < totalWidth * -1) {
      this.slideX = totalWidth * -1 + window.innerWidth - this.adjustment;
    }
  }

  getMoreDetails() {
    this.router.navigate(['/','our-solutions']);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
