import { InformationBoxComponent } from './../information-box/information-box.component';
import { MainService } from './../../../services/main.service';
import { UtilityService } from './../../../services/utility.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-apply-position',
  templateUrl: './apply-position.component.html',
  styleUrls: ['./apply-position.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplyPositionComponent implements OnInit, OnDestroy {
  @ViewChild('fileUploadInput', {static: true}) fileUploadInput: ElementRef;

  subscriptions = [];
  language = 'en';

  uploadSub$: Subscription;
  applyPositionData = {
    name: '',
    email: '',
    motivation: '',
    uploadedFile: null,
    isAgreed: false
  };

  nameValidationErr = false;
  emailValidationErr = false;
  errMsg = '';

  constructor(public dialogRef: MatDialogRef<ApplyPositionComponent>,
    @Inject(MAT_DIALOG_DATA) public service: any,
    private mainService: MainService,
    private utilityService: UtilityService,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for closing apply position dialog component
   */
  closeDialog() {
    this.dialogRef.close(false);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening the teams component as pop up dialog box
   */
  openTeamsConditions() {
    this.dialogRef.close(true);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for checking whether CV is ready to submitted or not
   */
  cvReadyToSendValidation() {
    if (this.applyPositionData.name.trim() === ''
        || this.applyPositionData.email.trim() === ''
        || this.applyPositionData.uploadedFile === null
        || !this.applyPositionData.isAgreed) {
      return true;
    }
    return false;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for validation of file upload box
   */
  onFileUpload(event) {
    this.fileUploadInput.nativeElement.classList.remove('red-border');
    if (event.target.files.length > 0) {
      this.applyPositionData.uploadedFile = event.target.files[0];
      this.uploadFileValidationCheck();
    } else {
      this.applyPositionData.uploadedFile = null;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for checking upload file type and its size
   */
  uploadFileValidationCheck() {
    if (this.applyPositionData.uploadedFile.type !== 'application/pdf') {
      this.errMsg = 'Only PDF format files are supported';
      this.showFileInputError();
      this.applyPositionData.uploadedFile = null;
    } else if (this.applyPositionData.uploadedFile.size > 2097152) {
      this.errMsg = 'Uploaded file size should be less than 2 MB';
      this.applyPositionData.uploadedFile = null;
      this.showFileInputError();
    }

    setTimeout(() => {
      this.errMsg = '';
    },3000);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for enabling validation error if anything validated as false
   */
  showFileInputError() {
    this.fileUploadInput.nativeElement.classList.add('red-border');
    this.fileUploadInput.nativeElement.value = null;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for uploading a CV
   */
  uploadCV() {
    const validations =[];
    if (this.applyPositionData.name.trim() === '') {
      this.nameValidationErr = true;
      validations.push('name');
    }

    if (this.applyPositionData.email.trim() === ''
        || !this.utilityService.isEmailValid(this.applyPositionData.email)) {
      this.emailValidationErr = true;
      validations.push('email');
    }

    if (validations.length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append("name", this.applyPositionData.name);
    formData.append("email", this.applyPositionData.email);
    formData.append("motivation", this.applyPositionData.motivation);
    formData.append("category", this.language === 'en' ? this.service.category_en:this.service.category_pt);
    formData.append("filename", this.applyPositionData.uploadedFile.name);
    formData.append("cv", this.applyPositionData.uploadedFile);

    this.uploadSub$ = this.mainService.uploadCV(formData).subscribe(res => {
      this.closeDialog();
      const ref = this.dialog.open(InformationBoxComponent, {
        width: '400px',
        panelClass: 'information-box-panel',
        data: {
          message: 'You CV has been uploaded successfully.'
        },
        disableClose: true
      });
    }, err => {
      console.error(err);
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
