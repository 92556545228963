import { HeaderService } from './../../../services/header.service';
import { GetInTouchComponent } from './../../common/get-in-touch/get-in-touch.component';
import { TeamsConditionsComponent } from './../../common/teams-conditions/teams-conditions.component';
import { InformationBoxComponent } from './../../common/information-box/information-box.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-work-style',
  templateUrl: './work-style.component.html',
  styleUrls: ['./work-style.component.scss']
})
export class WorkStyleComponent implements OnInit, OnDestroy {
  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'workstyle-home';

  constructor(private dialog: MatDialog,
    private headerService: HeaderService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening Get in Touch component for user inputs
   */
  getInTouch() {
    const ref = this.dialog.open(GetInTouchComponent, {
      width: '50vw',
      panelClass: 'get-in-touch-panel',
      disableClose: true
    });
    ref.afterClosed().subscribe(res => {
      if (res.status === true) {
        const dialogRef = this.dialog.open(TeamsConditionsComponent, {
          width: '80vw',
          panelClass: 'teams-condition-panel',
          disableClose: true
        });
      } else if (res.status === false) {
        const ref = this.dialog.open(InformationBoxComponent, {
          width: '400px',
          panelClass: 'information-box-panel',
          data: {
            message: res.message
          },
          disableClose: true
        });
      }
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
