import { HeaderService } from './../../services/header.service';
import { AdminService } from './../admin/services/admin.service';
import { TeamsConditionsComponent } from './../common/teams-conditions/teams-conditions.component';
import { GetInTouchComponent } from './../common/get-in-touch/get-in-touch.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InformationBoxComponent } from '../common/information-box/information-box.component';

@Component({
  selector: 'app-partners-page',
  templateUrl: './partners-page.component.html',
  styleUrls: ['./partners-page.component.scss']
})
export class PartnersPageComponent implements OnInit, OnDestroy {
  @ViewChild ('slider', {static: false}) slider: ElementRef;

  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'partners-page';

  successStoryContent = [
    {
      uuid: '',
      name_en: '',
      name_pt: '',
      content_en: '',
      content_pt: '',
      external_link: ''
    }
  ];

  constructor(private router: Router,
    private headerService: HeaderService,
    private adminService: AdminService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });

    const sub = this.adminService.getSuccessStoryContent().subscribe(res => {
      this.successStoryContent = res.result;
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub);
  }

   /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
  updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event
   * Description: Method for opening navigation menu for smaller screens
   */
  openMenu(e) {
    e.target.parentNode.classList.toggle('open');
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event
   * Description: Method for closing navigation menu for smaller screens
   */
  closeMenu() {
    this.slider.nativeElement.classList.remove('open');
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: path
   * Description: Method for navigating to path passed as argument
   */
  navigateToSolutionPage(path) {
    this.router.navigate(['/', path]);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening Get in Touch component for user inputs
   */
  getInTouch() {
    const ref = this.dialog.open(GetInTouchComponent, {
      width: '50vw',
      panelClass: 'get-in-touch-panel',
      disableClose: true
    });
    ref.afterClosed().subscribe(res => {
      if (res.status === true) {
        const dialogRef = this.dialog.open(TeamsConditionsComponent, {
          width: '80vw',
          panelClass: 'teams-condition-panel',
          disableClose: true
        });
      } else if (res.status === false) {
        const ref = this.dialog.open(InformationBoxComponent, {
          width: '400px',
          panelClass: 'information-box-panel',
          data: {
            message: res.message
          },
          disableClose: true
        });
      }
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
