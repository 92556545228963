import { AdminService } from './../../admin/services/admin.service';
import { HeaderService } from './../../../services/header.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'about-home';

  aboutContent = {
		heading_en: "",
		heading_pt: "",
		paragraph1_en: "",
		paragraph1_pt: "",
		paragraph2_en: "",
		paragraph2_pt: "",
		temp1_en: "",
		temp1_pt: "",
		temp2_en: "",
		temp2_pt: ""
	}

  constructor(private headerService: HeaderService,
    private adminService: AdminService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });

    const sub = this.adminService.getAboutContent().subscribe(res => {
      this.aboutContent = res.result;
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for changing the about image position based on the CSS variable updates
   */
  getNextCard() {
    const cardList = document.querySelectorAll('.card-container .card');
    const totalCards = cardList.length;
    cardList.forEach(card => {
      let value:any = getComputedStyle(card).getPropertyValue('--i');
      if (value === '0') {
        card['style'].setProperty('--i', totalCards - 1);
      } else {
        card['style'].setProperty('--i', (value - 1).toString())
      }
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
