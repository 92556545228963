import { AuthService } from 'src/app/auth/services/auth.service';
import { UtilityService } from './../../../services/utility.service';
import { AdminService } from './../services/admin.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-in-touch-page',
  templateUrl: './get-in-touch-page.component.html',
  styleUrls: ['./get-in-touch-page.component.scss']
})
export class GetInTouchPageComponent implements OnInit, OnDestroy {
  subscriptions = [];
  queryData = [];

  constructor(private adminService: AdminService,
    private utilityService: UtilityService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    const sub = this.adminService.getAllQueryInfodata().subscribe(res => {
      this.queryData = res.result;
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: date
   * Description: Method for getting date in proper format based on the passed date string
   */
  getDate(d) {
    return this.utilityService.getDate(new Date(parseInt(d)));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating back to the previous page
   */
  back() {
    window.history.back();
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for logging out from the system for admin role users
   */
  logout() {
    this.authService.logout()
      .subscribe(success => {
        if (success) {
          this.router.navigate(['/admin-login']);
        }
      });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
