import { HeaderService } from './services/header.service';
import { AdminService } from './components/admin/services/admin.service';
import { UtilityService } from './services/utility.service';
import { MainService } from './services/main.service';
import { AuthModule } from './auth/auth.module';
import { BusinessSolutionsComponent } from './components/home-page/business-solutions/business-solutions.component';
import { CompaniesComponent } from './components/home-page/companies/companies.component';
import { MeetTeamComponent } from './components/home-page/meet-team/meet-team.component';
import { TechnicalSolutionsScrollerComponent } from './components/home-page/technical-solutions-scroller/technical-solutions-scroller.component';
import { HeaderComponent } from './components/home-page/header/header.component';
import { HomeComponent } from './components/home-page/home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SolutionsPageComponent } from './components/solutions-page/solutions-page.component';
import { GlobalTeamPageComponent } from './components/global-team-page/global-team-page.component';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { PartnersPageComponent } from './components/partners-page/partners-page.component';
import { MatDialogModule, MatProgressBarModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/home-page/footer/footer.component';
import { PartnersHomeComponent } from './components/home-page/partners-home/partners-home.component';
import { WorkStyleComponent } from './components/home-page/work-style/work-style.component';
import { AboutComponent } from './components/home-page/about/about.component';
import { TeamsConditionsComponent } from './components/common/teams-conditions/teams-conditions.component';
import { ApplyPositionComponent } from './components/common/apply-position/apply-position.component';
import { GetInTouchComponent } from './components/common/get-in-touch/get-in-touch.component';
import { SettingsComponent } from './components/admin/settings/settings.component';
import { InformationBoxComponent } from './components/common/information-box/information-box.component';
import { GetInTouchPageComponent } from './components/admin/get-in-touch-page/get-in-touch-page.component';
import { ApplyPositionsPageComponent } from './components/admin/apply-positions-page/apply-positions-page.component';
import { ViewPdfComponent } from './components/common/view-pdf/view-pdf.component';
import { FallbackImgDirective } from './directives/fallback-img.directive';
import { PhotoGalleryComponent } from './components/about-page/photo-gallery/photo-gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PartnersHomeComponent,
    TechnicalSolutionsScrollerComponent,
    WorkStyleComponent,
    BusinessSolutionsComponent,
    CompaniesComponent,
    MeetTeamComponent,
    HomeComponent,
    AboutComponent,
    SolutionsPageComponent,
    GlobalTeamPageComponent,
    AboutPageComponent,
    PartnersPageComponent,
    TeamsConditionsComponent,
    ApplyPositionComponent,
    GetInTouchComponent,
    SettingsComponent,
    InformationBoxComponent,
    GetInTouchPageComponent,
    ApplyPositionsPageComponent,
    ViewPdfComponent,
    FallbackImgDirective,
    PhotoGalleryComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    MatDialogModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    MatProgressBarModule
  ],
  providers: [
    MainService,
    HeaderService,
    AdminService,
    UtilityService
  ],
  entryComponents: [
    TeamsConditionsComponent,
    ApplyPositionComponent,
    GetInTouchComponent,
    InformationBoxComponent,
    ViewPdfComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
