import { AuthService } from 'src/app/auth/services/auth.service';
import { ViewPdfComponent } from './../../common/view-pdf/view-pdf.component';
import { UtilityService } from './../../../services/utility.service';
import { AdminService } from './../services/admin.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply-positions-page',
  templateUrl: './apply-positions-page.component.html',
  styleUrls: ['./apply-positions-page.component.scss']
})
export class ApplyPositionsPageComponent implements OnInit, OnDestroy {
  subscriptions = [];
  applyPositions = [];
  candidateCV = [];

  constructor(private adminService: AdminService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    const sub = this.adminService.getAllAppliedCandidates().subscribe(res => {
      this.applyPositions = res.result;
    }, err => {
      console.error(err);
    });

    const sub1 = this.adminService.getCandidateCV().subscribe(res => {
      this.candidateCV = res.result;
    }, err => {
      console.error(err);
    });

    this.subscriptions.push(sub);
    this.subscriptions.push(sub1);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: date
   * Description: Method for getting a date in proper format by passing date string
   */
  getDate(d) {
    return this.utilityService.getDate(new Date(parseInt(d)));
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: path
   * Description: Method for showing PDF by providing its uploading CV path
   */
  getCV(path) {
    this.dialog.open(ViewPdfComponent, {
      width: '90vw',
      panelClass: 'information-box-panel',
      data: {
        pdfData: path
      },
      disableClose: true
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating back to the previous visited page
   */
  back() {
    window.history.back();
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for logging out from the admin role
   */
  logout() {
    this.authService.logout()
      .subscribe(success => {
        if (success) {
          this.router.navigate(['/admin-login']);
        }
      });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
