import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  // Method for proving common headers in Rest call request
  getHTTPHeaderForAjaxCalls() {
    return {
      headers: new  HttpHeaders({
        'Content-Type': 'application/json',
        'Accept' : 'application/json',
        'Authorization': 'Basic ' + btoa('Administrator:Laura2020')
      }),
      withCredentials: true
    };
  }

  // Method for find out whether a input is defined or not
  isDefined(input) {
    return !(input === undefined || input === null);
  }

  // Method for find out whether a input is undefined or not
  isUndefined(input) {
    return (input === undefined || input === null);
  }

  // Method for find out whether a input is empty or not
  isEmpty(input) {
    if (this.isUndefined(input)) {
      return true;
    }
    return (input.trim() === '');
  }

  // Method for preventing all default behavior for drag and drop events
  preventDefaultBehavior(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  // Method for downloading file
  downloadFile(req: any, endPointAddress: string, url: string, newTab: boolean) {

    const newDiv = document.createElement('div');
    newDiv.id = 'formParent';
    newDiv.style.display = 'none';
    document.querySelector('body').appendChild(newDiv);
    const newForm = document.createElement('form');
    newForm.method = 'POST';
    if (newTab) {
      newForm.target = '_blank';
    }
    newForm.action = endPointAddress + url;

    const newInput = document.createElement('input');
    newInput.type = 'textarea';
    newInput.id = 'inputData';
    newInput.name = 'inputData';
    newInput.value = JSON.stringify(req);
    newForm.appendChild(newInput);

    const newBtn = document.createElement('button');
    newBtn.id = 'dwnBtn';
    newBtn.type = 'submit';
    newForm.appendChild(newBtn);

    newDiv.appendChild(newForm);
    setTimeout(function () {
      newBtn.click();
      newDiv.remove();
    }, 0);
  }

  // Method for checking an email is valid or not
  isEmailValid(email) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  // Generate random numbers
  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  // Method for checking a valid integer or not
  isValuePositiveNumber(input) {
    if (input.trim() === '') return false;
    try {
      if (Number.isInteger(input - 0) && input >= 0) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  // Method for going back webpage
  back() {
    window.history.back();
  }

  // Method for getting date format
  getDateFormat(date) {
    if (date instanceof Date) {
      const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];
      return date.getDate() + '-' + month[date.getMonth()] + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    } else {
      return date;
    }
  }
  
  // Method for getting date format
  getDate(date) {
    if (date instanceof Date) {
      const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'];
      return date.getDate() + '-' + month[date.getMonth()] + '-' + date.getFullYear();
    } else {
      return date;
    }
  }

  // Method for getting days from current date
  getDaysFromCurrentDate(date) {
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const year = day * 365;

    const d = new Date().getTime() - new Date(date).getTime();
    return Math.round(d / day);
  }
}
