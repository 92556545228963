import { HeaderService } from './../../../services/header.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-solutions',
  templateUrl: './business-solutions.component.html',
  styleUrls: ['./business-solutions.component.scss']
})
export class BusinessSolutionsComponent implements OnInit, OnDestroy {
  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'business-solution-home';

  category = 'outsourcing';
  slideX:any = 0;
  constructor(private headerService: HeaderService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: category
   * Description: Method for updating category based on the user click
   */
  updateCategory(category) {
    if (category === 'outsourcing') {
      this.slideX = 0;
    } else if (category === 'nearshore') {
      this.slideX = -100;
    } else if (category === 'software') {
      this.slideX = -200;
    }
    this.category = category;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for getting previous category based on the navigation clicked
   */
  updateCategoryPrev() {
    if (this.category === 'software') {
      this.updateCategory('nearshore');
    } else if (this.category === 'nearshore') {
      this.updateCategory('outsourcing');
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for getting next category based on the navigation clicked
   */
  updateCategoryNext() {
    if (this.category === 'outsourcing') {
      this.updateCategory('nearshore');
    } else if (this.category === 'nearshore') {
      this.updateCategory('software');
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
