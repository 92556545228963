import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-teams-conditions',
  templateUrl: './teams-conditions.component.html',
  styleUrls: ['./teams-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamsConditionsComponent implements OnInit, OnDestroy {
  subscriptions = [];
  constructor(public dialogRef: MatDialogRef<TeamsConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public service: any) { }

  ngOnInit() {
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for closing apply position dialog component
   */
  closeDialog() {
    this.dialogRef.close(true);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
