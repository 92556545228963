import { HeaderService } from './../../services/header.service';
import { AdminService } from './../admin/services/admin.service';
import { InformationBoxComponent } from './../common/information-box/information-box.component';
import { MainService } from './../../services/main.service';
import { TeamsConditionsComponent } from './../common/teams-conditions/teams-conditions.component';
import { GetInTouchComponent } from './../common/get-in-touch/get-in-touch.component';
import { ApplyPositionComponent } from './../common/apply-position/apply-position.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { config } from './../../config';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-global-team-page',
  templateUrl: './global-team-page.component.html',
  styleUrls: ['./global-team-page.component.scss']
})
export class GlobalTeamPageComponent implements OnInit, OnDestroy {
  @ViewChild ('slider', {static: false}) slider: ElementRef;
  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;

  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'global-team-page';

  positionName = '';
  slideX:any = 0;
  scrollAmount = window.innerWidth - 300;

  fileName = '';
  uploadProgress:number;
  uploadSub$: Subscription;

  careerContent = [
    {
      uuid: '',
      name_en: '',
      name_pt: '',
      desg_en: '',
      desg_pt: '',
      year_of_exp_en: '',
      year_of_exp_pt: '',
      products_en: '',
      products_pt: '',
      core_en: '',
      core_pt: '',
      location_en: '',
      location_pt: '',
      temp1_en: '',
      temp1_pt: ''
    }
  ];

  constructor(private router: Router,
    private dialog: MatDialog,
    private headerService: HeaderService,
    private adminService: AdminService,
    private mainService: MainService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });

    const sub = this.adminService.getCareerContent().subscribe(res => {
      this.careerContent = res.result;
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
  updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event
   * Description: Method for opening navigation menu for smaller screens
   */
  openMenu(e) {
    e.target.parentNode.classList.toggle('open');
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event
   * Description: Method for closing navigation menu for smaller screens
   */
  closeMenu() {
    this.slider.nativeElement.classList.remove('open');
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: tabname
   * Description: Method for setting the active tab base on the tab name passed
   */
  setActiveTab(tab) {
    this.positionName = tab;
    this.slideX = tab * -100;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: path
   * Description: Method for navigating to path passed as argument
   */
  navigateToSolutionPage(path) {
    this.router.navigate(['/', path]);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for sliding the open positions container to left
   */
  slideLeft() {
    this.slideX += 338;
    if (this.slideX > 0) {
      this.slideX = 0;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for sliding the open positions container to right
   */
  slideRight() {
    const adjustment = 230;
    this.slideX -= 338;
    const totalWidth = document.querySelector('.position-container')['scrollWidth'];
    if (this.slideX - window.innerWidth + adjustment < totalWidth * -1) {
      this.slideX = totalWidth * -1 + window.innerWidth - adjustment;
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: career
   * Description: Method for opening a pop up for the user to upload CV and other details
   */
  applyPosition(cr) {
    const ref = this.dialog.open(ApplyPositionComponent, {
      width: '50vw',
      data: {
        category_en: cr.name_en,
        category_pt: cr.name_pt,
      },
      panelClass: 'apply-position-panel',
      disableClose: true
    });
    ref.afterClosed().subscribe(res => {
      if (res) {
        console.log(res);
      }
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening Get in Touch component for user inputs
   */
  getInTouch() {
    const ref = this.dialog.open(GetInTouchComponent, {
      width: '50vw',
      panelClass: 'get-in-touch-panel',
      disableClose: true
    });
    ref.afterClosed().subscribe(res => {
      if (res.status === true) {
        const dialogRef = this.dialog.open(TeamsConditionsComponent, {
          width: '80vw',
          panelClass: 'teams-condition-panel',
          disableClose: true
        });
      } else if (res.status === false) {
        const ref = this.dialog.open(InformationBoxComponent, {
          width: '400px',
          panelClass: 'information-box-panel',
          data: {
            message: res.message
          },
          disableClose: true
        });
      }
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event
   * Description: Method for uploading a CV in general category
   */
  uploadCV(event) {
    const file:File = event.target.files[0];

    if (file) {
      //validation goes here
      if (file.type !== 'application/pdf' ) {
        this.showUploadFileMsg(this.displayNames.onlyPDF);
        return;
      }

      if (file.size > 2097152 ) {
        this.showUploadFileMsg(this.displayNames.maxPDFSize);
        return;
      }

      this.fileName = file.name;
      const formData = new FormData();
      formData.append("cv", file);
      formData.append('filename', file.name);

      this.uploadProgress = null;
      this.uploadSub$ = this.mainService.upload(formData).subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.fileName = 'Upload...';
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
        }
        if (this.uploadProgress === 100) {
          this.showUploadFileMsg(this.displayNames.uploadSuccessful);
          this.reset();
        }
      }, err => {
        this.showUploadFileMsg(this.displayNames.uploadError);
      });
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for to show validation message to the user
   */
  showUploadFileMsg(msg) {
    this.fileName = msg;
    this.fileUpload.nativeElement.value = null;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for is to cancel the ongoing cv uploading
   */
  cancelUpload() {
    this.showUploadFileMsg('Uploading CV has been cancelled');
    this.uploadSub$.unsubscribe();
    this.reset();
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for resetting the upload CV container after CV upload cancellation
   */
  reset() {
    this.uploadProgress = null;
    this.uploadSub$ = null;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
