import { HeaderService } from './../../../services/header.service';
import { InformationBoxComponent } from './../../common/information-box/information-box.component';
import { MainService } from './../../../services/main.service';
import { UtilityService } from './../../../services/utility.service';
import { TeamsConditionsComponent } from './../../common/teams-conditions/teams-conditions.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'footer-page';

  invalidEmail = false;
  invalidMsg = false;
  acceptTeams = false;

  email = '';
  message = '';

  constructor(private dialog: MatDialog,
    private mainService: MainService,
    private headerService: HeaderService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening terms component as pop up dialog box
   */
  openTeamsConditions() {
    const ref = this.dialog.open(TeamsConditionsComponent, {
      width: '80vw',
      panelClass: 'teams-condition-panel',
      disableClose: true
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for sending message to backend DB after validation check
   */
  sendMessage() {
    const err = [];
    if (this.email.trim() === '' || !this.utilityService.isEmailValid(this.email)) {
      this.invalidEmail = true;
      err.push('email');
    }
    if (this.message.trim() === '') {
      this.invalidMsg = true
      err.push('message');
    }
    if(err.length > 0) {
      return;
    }

    const userData = {
      name: '',
      email: this.email,
      subject: '',
      message: this.message
    }

    this.mainService.getInTouch(userData).subscribe(res => {
      const ref = this.dialog.open(InformationBoxComponent, {
        width: '400px',
        panelClass: 'information-box-panel',
        data: {
          message: res.message
        },
        disableClose: true
      });

      ref.afterClosed().subscribe(() => {
        this.email = '';
        this.message = '';
        this.acceptTeams = false;
      });
    }, err => {
      console.error(err);
    })
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
