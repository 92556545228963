import { HeaderService } from './../../services/header.service';
import { AdminService } from './../admin/services/admin.service';
import { InformationBoxComponent } from './../common/information-box/information-box.component';
import { TeamsConditionsComponent } from './../common/teams-conditions/teams-conditions.component';
import { GetInTouchComponent } from './../common/get-in-touch/get-in-touch.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-solutions-page',
  templateUrl: './solutions-page.component.html',
  styleUrls: ['./solutions-page.component.scss']
})
export class SolutionsPageComponent implements OnInit, OnDestroy {
  @ViewChild ('slider', {static: false}) slider: ElementRef;
  @ViewChild ('slideContent', {static: false}) slideContent: ElementRef;
  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'solutions-page';

  activeTab = 0;
  slideX = 0;

  solutionContent = [
      {
      uuid:  "1",
      name_en: "PROCESS EXCELLENCE & REENGINEERING",
      name_pt: "EXCELÊNCIA E REENGENHARIA DE PROCESSOS",
      head_en: "You can´t transform and improve what you don´t know!",
      head_pt: "Você não pode transformar e melhorar o que você não conhece!",
      para1_en: "We provide the tools to rethink how your company does its work in order to improve customer service, cut operational costs, and become a world-class competitor. To do this we focus on the analysis and design of the workflows and business processes within your organization and find the best way to achieve a dramatic improvement with a top down implementation with high organizational impact. This allows you to gain quality in your solutions, reduce costs and eliminate bureaucratic tasks, and boost process effectiveness and efficiency.",
      para1_pt: "Fornecemos as ferramentas para repensar a forma como sua empresa trabalha, a fim de melhorar o atendimento ao cliente, cortar custos operacionais e se tornar um concorrente de classe mundial. Para fazer isso, nos concentramos na análise e design dos fluxos de trabalho e processos de negócios dentro de sua organização e encontramos a melhor maneira de alcançar uma melhoria dramática com uma implementação de cima para baixo com alto impacto organizacional. Isso permite que você ganhe qualidade em suas soluções, reduza custos e elimine tarefas burocráticas, além de aumentar a eficácia e eficiência dos processos.",
      para2_en: "",
      para2_pt: "",
      para3_en: "",
      para3_pt: "",
      para4_en: "",
      para4_pt: ""
    }
  ];

  constructor(private router: Router,
    private headerService: HeaderService,
    private adminService: AdminService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });

    const sub = this.adminService.getSolutionContent().subscribe(res => {
      this.solutionContent = res.result;
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
  updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event
   * Description: Method for opening navigation menu for smaller screens
   */
  openMenu(e) {
    e.target.parentNode.classList.toggle('open');
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: event
   * Description: Method for closing navigation menu for smaller screens
   */
  closeMenu() {
    this.slider.nativeElement.classList.remove('open');
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: tabname
   * Description: Method for setting the active tab base on the tab name passed
   */
  setActiveTab(tab) {
    this.activeTab = tab;
    this.slideX = tab * -100;
    this.slideContent.nativeElement.transitionDuration = '0s';
    this.slideContent.nativeElement.classList.add('no-opacity');
    setTimeout(() => {
      this.slideContent.nativeElement.transitionDuration = '0.3s';
      this.slideContent.nativeElement.classList.remove('no-opacity');
    }, 300);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: path
   * Description: Method for navigating to path passed as argument
   */
  navigateToSolutionPage(path) {
    this.router.navigate(['/', path]);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for opening Get in Touch component for user inputs
   */
  getInTouch() {
    const ref = this.dialog.open(GetInTouchComponent, {
      width: '50vw',
      panelClass: 'get-in-touch-panel',
      disableClose: true
    });
    ref.afterClosed().subscribe(res => {
      if (res.status === true) {
        const dialogRef = this.dialog.open(TeamsConditionsComponent, {
          width: '80vw',
          panelClass: 'teams-condition-panel',
          disableClose: true
        });
      } else if (res.status === false) {
        const ref = this.dialog.open(InformationBoxComponent, {
          width: '400px',
          panelClass: 'information-box-panel',
          data: {
            message: res.message
          },
          disableClose: true
        });
      }
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
