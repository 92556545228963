import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[fallback]'
})
export class FallbackImgDirective {

  @Input()
  @HostBinding('src')
  src: string;

  @Input() fallback: string;

  @HostListener('error')
  onError() {
    this.src = this.fallback;
  }
}
