import { HeaderService } from './../../../services/header.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {

  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'about-page';

  slideX = 0;
  constructor(private headerService: HeaderService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  scroll(type) {
    if (type === 'left') {
      this.slideX += 100;
      if (this.slideX > 0) {
        this.slideX = 0;
      }
    } else if (type === 'right') {
      this.slideX -= 100;
    }
    console.log(this.slideX);

  }
}
