import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  subscriptions = [];
  constructor() { }

  ngOnInit() {
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
