import { HeaderService } from './../../../services/header.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit, OnDestroy {
  subscriptions = [];
  language = '';
  displayNames:any = {};
  pageName = 'companies-home';

  slideX = 0;
  companies = [];
  startCompanyIndex = 1;
  endCompanyIndex = 45;
  forwardAddedNumber = 45;
  backwardAddedNumber = 45;

  constructor(private headerService: HeaderService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });

    for (let index = this.startCompanyIndex; index <= this.forwardAddedNumber; index++) {
      this.companies.push(index);
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
    this.displayNames = this.headerService.displayNames[this.language][this.pageName];
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to previous partners
   */
   prev() {
    if (window.innerWidth < 480) {
      this.slideX += 100;
    } else {
      this.slideX += 250;
    }
    if (this.slideX > 0) {
      this.slideX = 0;
      this.companies = [this.backwardAddedNumber].concat(this.companies);
      this.backwardAddedNumber--;
      if (this.backwardAddedNumber < this.startCompanyIndex) {
        this.backwardAddedNumber = this.endCompanyIndex;
      }

      this.companies = this.companies.splice(0, this.companies.length - 1);
      this.forwardAddedNumber--;
      if (this.forwardAddedNumber < this.startCompanyIndex) {
        this.forwardAddedNumber = this.endCompanyIndex;
      }
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for navigating to next partners
   */
  next() {
    const adjustment = 0;
    if (window.innerWidth < 480) {
      this.slideX -= 100;
    } else {
      this.slideX -= 250;
    }
    const links = document.querySelector('.companies-container .companies-data .companies');
    const scrollWidth = links['scrollWidth'];
    const totalWidth = links['offsetWidth'];
    if (this.slideX < (scrollWidth - totalWidth) * -1) {
      this.slideX = (scrollWidth - totalWidth) * -1;
      let addedCount = 0;
      for (let index = this.forwardAddedNumber + 1; index <= this.forwardAddedNumber + 2; index++) {
        if (index > this.endCompanyIndex) {
          continue;
        }
        this.companies.push(index);
        addedCount++;
      }
      this.forwardAddedNumber += addedCount;

      if (this.forwardAddedNumber >= this.endCompanyIndex) {
        this.forwardAddedNumber = 0;
      }

      this.companies = this.companies.splice(addedCount, this.companies.length);
      this.backwardAddedNumber += addedCount;
      if (this.backwardAddedNumber > this.endCompanyIndex) {
        this.backwardAddedNumber = 1;
      }
    }
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
