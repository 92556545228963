import { HeaderService } from './../../../services/header.service';
import { AdminService } from './../../admin/services/admin.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-meet-team',
  templateUrl: './meet-team.component.html',
  styleUrls: ['./meet-team.component.scss']
})
export class MeetTeamComponent implements OnInit, OnDestroy {
  subscriptions = [];
  language = '';

  memberContent = [
    {
      uuid: '',
      name_en: '',
      name_pt: '',
      positionPriority: '',
      temp1_en: '',
      temp1_pt: '',
      temp2_en: '',
      temp2_pt: '',
    }
  ];

  constructor(private adminService: AdminService,
    private headerService: HeaderService) { }

  ngOnInit() {
    this.updateLanguage(this.headerService.getLanguaage());
    this.headerService.language$.subscribe(language => {
      this.updateLanguage(language);
    });

    const sub4 = this.adminService.getTeamMemberContent().subscribe(res => {
      this.memberContent = res.result;
    }, err => {
      console.error(err);
    });
    this.subscriptions.push(sub4);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for updating language based on the language configured in hearderService
  */
   updateLanguage(language) {
    this.language = language;
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: language
   * Description: Method for setting language and broadcast the updated language
   */
  setLanguage(language) {
    this.headerService.language$.next(language);
  }

  /**
   * Company: Findmore Digital, Dubai
   * Year: 2022
   * Owner: Surajit Das
   * Input Fields: N/A
   * Description: Method for releasing the resources while this component destroying
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
